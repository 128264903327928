import * as React from 'react';

import type { PageProps } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

import npsSvg from 'stile-shared/assets/images/what-teachers-are-saying/graphic_nps.svg';

import { HeroBlock } from 'stile-shared/src/components/2020/blocks/HeroBlock';
import { TextBlock } from 'stile-shared/src/components/2020/blocks/TextBlock';
import { CaseStudy } from 'stile-shared/src/components/2020/common/CaseStudy';
import { H2, P, Section } from 'stile-shared/src/components/2020/common/Primitives';
import { TestimonialGroup } from 'stile-shared/src/components/2020/common/TestimonialGroup';
import { VideoModalLauncher } from 'stile-shared/src/components/2020/common/VideoModalLauncher';
import { Center } from 'stile-shared/src/components/2020/layout/Center';
import { Column } from 'stile-shared/src/components/2020/layout/Column';

import { getPageData } from 'utils/getPageData';

import { theme } from 'styles/theme';

import { PageLayout } from 'templates/2020/PageLayout';

import { CtaBlock } from 'components/blocks/CtaBlock';
import { NextPageBlock } from 'components/blocks/NextPageBlock';

export { Head } from 'templates/2020/Head';

function WhatTeachersAreSayingPage(props: PageProps) {
  const { hero } = getPageData(props.location.pathname);

  return (
    <PageLayout {...props}>
      <HeroBlock
        {...hero}
        image={
          <StaticImage
            loading="eager"
            aspectRatio={2.37}
            src="../../../../stile-shared/assets/images/what-teachers-are-saying/cover_what_teachers_are_saying.png"
            alt="Two teachers working together with the Stile app to plan a class"
          />
        }
      />

      <TextBlock>
        <Column space={theme.space.l}>
          <Column space={theme.space.xxs}>
            <P>In late 2023, we asked over 1350 teachers a simple question:</P>
            <P italic>
              “On a scale from 0 to 10, how likely are you to recommend Stile to other science
              teachers?”
            </P>
          </Column>

          <Center max={theme.space.measure}>
            <img
              src={npsSvg}
              alt="A vertical bar chart showing that 39% of teachers responded 10/10, and 15% of teachers responded 9/10"
            />
          </Center>
        </Column>
      </TextBlock>

      <Section>
        <Center max={theme.space.measure}>
          <Column space={theme.space.l}>
            <H2>Hear what else they had to say:</H2>
            <CaseStudy
              title="Jovana discusses how she's leveraging Stile's customizability to engage all learners."
              text="Jovana Ledesma at Ingenium Charter Middle School, CA"
              url="https://stileapp.com/go/igms_case_study"
              image={
                <StaticImage
                  src="../../../../stile-shared/assets/images/what-teachers-are-saying/jovana.png"
                  alt="A round headshot of Jovana"
                />
              }
            />
          </Column>
        </Center>
      </Section>

      <Section>
        <Center max={theme.space.measure}>
          <Column space={theme.space.section}>
            <Column space={theme.space.mToL}>
              <VideoModalLauncher
                text="Hear from Jovana (2 mins)"
                url="https://youtu.be/V0aDHtUdwyk"
                coverImage={
                  <StaticImage
                    src="../../../../stile-shared/assets/images/what-teachers-are-saying/icms_video_cover.jpg"
                    alt="Jovana is shown sitting on a table in her classroom, smiling at the camera"
                  />
                }
              />
            </Column>

            <Section>
              <Center max={theme.space.measure}>
                <Column space={theme.space.l}>
                  <CaseStudy
                    title="Laura and Eric discuss their experience with Stile and how their students have found it."
                    text="Laura Spanier at A.P Giannini Middle School and Eric Lewis at the San Francisco Unified School District, CA"
                    url="http://stileapp.com/go/sfusd_case_study"
                    image={
                      <StaticImage
                        src="../../../../stile-shared/assets/images/what-teachers-are-saying/eric_laura.png"
                        alt="Slightly overlapping round headshots of Eric and Laura"
                      />
                    }
                  />
                </Column>
              </Center>
            </Section>

            <TestimonialGroup
              title="About differentiation"
              image={
                /* decorative image does not require alt text */
                <StaticImage
                  src="../../../../stile-shared/assets/images/what-teachers-are-saying/spot_differentiation.png"
                  alt=""
                />
              }
              quotes={[
                {
                  text: 'The incorporation of multimodal activities and the ease to differentiate Stile activities has made it easy to engage all students in my classes.',
                  author: 'Cherie Nelson',
                  origin: 'Robina State High School',
                },
                {
                  text: 'Stile is a great tool to help me differentiate in the classroom – I can have students working on different tasks at their own speed. The variation in how the information is presented also helps the kids learn.',
                  author: 'Melinda Boulton',
                  origin: 'Diamond Valley College',
                },
                {
                  text: 'Stile allows us to personalize the learning of our students in our middle school program. Students access the Stile lessons that are at their point of need allowing the teacher to facilitate students learning multiple topics in a single classroom.',
                  author: 'Melanie Soklevski',
                  origin: 'Bundoora Secondary College',
                },
              ]}
            />

            <TestimonialGroup
              title="About giving feedback"
              image={
                /* decorative image does not require alt text */
                <StaticImage
                  src="../../../../stile-shared/assets/images/what-teachers-are-saying/spot_feedback.png"
                  alt=""
                />
              }
              quotes={[
                {
                  text: 'Stile allows me to give students regular feedback on their work and to encourage responses of the highest quality.',
                  author: 'Sarah King',
                  origin: 'Lara Secondary College',
                },
                {
                  text: 'My favourite part of Stile is the ability to give real-time feedback to students and being able to see students respond to the feedback immediately. It was great to see some Year 9s getting really excited to see what feedback their teacher had given them and to move ahead with their learning.',
                  author: 'Nicola Dow',
                  origin: 'Wellington High School',
                },
                {
                  text: 'I use Stile with my Year 9 and 10 students and it is an excellent resource which the students really engage with. The tasks are well structured, include up-to-date real-world information, cover a range of skills as well as providing the students with timely, relevant feedback.',
                  author: 'Heath McCabe',
                  origin: 'Hale School',
                },
              ]}
            />

            <TestimonialGroup
              title="About critical thinking"
              image={
                /* decorative image does not require alt text */
                <StaticImage
                  src="../../../../stile-shared/assets/images/what-teachers-are-saying/spot_critical.png"
                  alt=""
                />
              }
              quotes={[
                {
                  text: 'The positive impact that Stile is having in my classroom is... maximizing the engagement of students by providing science content relevant to the world around them, regular opportunities for participating in critical thinking and discussions, and receiving frequent, timely and personalized feedback to support the development of their knowledge and skills.',
                  author: 'Emma Watson',
                  origin: 'Trinity College',
                },
                {
                  text: 'Stile is a great program to help stimulate critical thinking around scientific concepts.',
                  author: 'Adam Lindsay',
                  origin: 'Myrtleford P12 College',
                },
                {
                  text: 'Stile is amazing for student engagement and developing critical thinking. I also appreciate how easy it is to customize units and lessons to our teaching.',
                  author: 'Michal Denny',
                  origin: 'Auckland Girls’ Grammar',
                },
              ]}
            />

            <TestimonialGroup
              title="About data-driven teaching"
              image={
                /* decorative image does not require alt text */
                <StaticImage
                  src="../../../../stile-shared/assets/images/what-teachers-are-saying/spot_datadriven.png"
                  alt=""
                />
              }
              quotes={[
                {
                  text: 'I’ve been a Stile user for 3 years now and it just keeps getting better and better. Complete flexibility, valuable learning tools, and the real-time data analytics – it’s indispensable and so simple to use.',
                  author: 'David Copping',
                  origin: 'Rosehill Secondary College',
                },
                {
                  text: 'Stile has allowed me to better identify and address student literacy issues, misconceptions, and areas of weakness in student understanding through Question Analytics and Quick Review features. It has saved time and made it easier in collating data that helps inform my teaching practices to better address student needs.',
                  author: 'Alex Hua',
                  origin: 'Duncraig Senior High School',
                },
                {
                  text: 'Stile makes it easy to track the learning progression of every student to support data-driven teaching.',
                  author: 'Lucy Hayes',
                  origin: 'UC High School Kaleen',
                },
              ]}
            />

            <TestimonialGroup
              title="About quality"
              image={
                /* decorative image does not require alt text */
                <StaticImage
                  src="../../../../stile-shared/assets/images/what-teachers-are-saying/spot_quality.png"
                  alt=""
                />
              }
              quotes={[
                {
                  text: 'Amazing pre-made content, and highly customizable, with a high amount of interactivity with the students, makes these lessons fun and engaging for all!',
                  author: 'Daniel Beek',
                  origin: 'Werribee Secondary College',
                },
                {
                  text: 'Stile is the resource I’d always imagined. It blends learning styles and quality pedagogy, provides collaboration opportunities, and allows for timely and effective feedback for both students and staff. Most importantly it keeps getting better.',
                  author: 'Chris Ferry',
                  origin: 'Magdalene Catholic High School',
                },
                {
                  text: 'I like to use Stile in my classrooms because it is made by passionate and expert science educators. The designs of lessons on the page look great, there are a wide range of activities for students to do and the lessons are flexible – I can teach it up the front, run group work, class brainstorms or facilitate differentiated, individual work.',
                  author: 'Simon Aylott',
                  origin: 'Daramalan College',
                },
              ]}
            />

            <TestimonialGroup
              title="About engagement in science"
              image={
                /* decorative image does not require alt text */
                <StaticImage
                  src="../../../../stile-shared/assets/images/what-teachers-are-saying/spot_engagement.png"
                  alt=""
                />
              }
              quotes={[
                {
                  text: 'We searched high and low for a platform that would deliver the exciting and stimulating lessons that we planned for our students, and Stile was it! It has enhanced engagement across the classroom and the facilitation of differentiated learning that Stile has enabled is effective and ever so simple to use. Our students love it as much as we do!',
                  author: 'Sacha Cross',
                  origin: 'Brisbane Girls Grammar School',
                },
                {
                  text: 'Stile improves our students’ engagement and scientific literacy and brings real scientific research into the classroom. The lessons are easily customizable and the Concierge team have been very helpful in aligning their content with our programs. Thanks Stile.',
                  author: 'Aidan Johnson',
                  origin: 'John Therry Catholic High School',
                },
                {
                  text: 'With Stile incorporated into my lessons, students are engaged in content but also encouraged to ask more questions beyond what the classroom will teach them.',
                  author: 'Matthew Wilson',
                  origin: 'St Laurence’s College',
                },
              ]}
            />
          </Column>
        </Center>
      </Section>

      <NextPageBlock path="/who-we-are/our-why/" />

      <CtaBlock />
    </PageLayout>
  );
}

export default WhatTeachersAreSayingPage;
